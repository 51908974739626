"use client";

import React, { PropsWithChildren, useEffect, useState } from "react";

import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ReactQueryStreamedHydration } from "@tanstack/react-query-next-experimental";
import { Slide, ToastContainer } from "react-toastify";

import { useGlobalStore } from "../zustand/initStore";

function Providers({ children }: PropsWithChildren) {
  const [client] = useState(new QueryClient());
  const { isMobileMenuOpen } = useGlobalStore();

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isMobileMenuOpen]);

  return (
    <QueryClientProvider client={client}>
      <ReactQueryStreamedHydration>
        {children}
        <ToastContainer limit={3} transition={Slide} />
      </ReactQueryStreamedHydration>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default Providers;
